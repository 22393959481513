<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      colaboradorData: null,
      currentView: 0,
    };
  },
  components: {
    ColaboradoresWindow: () => import("./colaboradores-window.vue"),
    ProjetosWindow: () => import("./projetos-window.vue"),
  },
  computed: {
    ...mapGetters(['clientId']),
  },
  watch: {
    clientId() {
      this.viewGeneral();
    },
  },
  methods: {
    viewDetails(data) {
      this.colaboradorData = data;
      this.currentView = 1;
    },
    viewGeneral() {
      this.colaboradorData = null;
      this.currentView = 0;
    },
  },
};
</script>

<template>
  <v-window v-model="currentView" reverse touchless>
    <v-window-item>
      <ColaboradoresWindow @viewDetails="viewDetails" />
    </v-window-item>
    <v-window-item>
      <ProjetosWindow
        v-if="!!colaboradorData"
        :data="colaboradorData"
        @back="viewGeneral"
      />
    </v-window-item>
  </v-window>
</template>
